export const GATED_POPUP_SOURCE = "gated_popup";
export const GATED_POPUP_REDIRECT_DATA = "redirect_data";
export const GATED_REDIRECT_URL = "gated_redirect_url";

export const GATED_ADD_TO_PRODUCT_SOURCE = "add_to_cart";
export const GATED_PRODUCT_PAGE = "product_page";
export const GATED_ADD_PRODUCT_TO_FAVOURITE_SOURCE = "add_product_to_favourite";
export const GATED_BRAND_TO_FAVOURITE_SOURCE = "add_brand_to_favourite";
export const GATED_CAROUSEL_PRODUCT_CARD_SOURCE = "carousel_product_card";
export const GATED_CAROUSEL_LAST_CARD_SOURCE = "carousel_last_card";
export const GATED_CAROUSEL_FAVOURITE_SOURCE =
  "carousel_product_card_add_to_favourtie";

export const URL_PARAM_EXTERNAL_SOURCE = "g_yes";
export const OTRIUM_NO_GATE_ENABLED = "otrium_no_gate_enabled";

export const GATED_PLP_PRODUCT_CARD_SOURCE = "plp_product_card";
export const GATED_PLP_FAVOURITE_SOURCE = "plp_product_card_add_to_favourtie";

export const GATED_BRAND_CAROUSEL_CARD_SOURCE = "brand_carousel_card";

export const GATED_BRAND_CAROUSEL_VIEW_ALL_SOURCE = "brand_carousel_view_all";
export const GATED_BRAND_CAROUSEL_FAVOURITE_SOURCE =
  "brand_carousel_card_add_to_favourtie";
