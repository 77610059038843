import { Trans } from "@lingui/macro";
import { find } from "lodash-es";
import {
  FC,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Text } from "@otrium/atoms";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import { useInView } from "react-intersection-observer";
import { Dropdown } from "src/atoms/DropDown";
import { FreeShipping } from "src/icons/FreeShipping";
import { FastDelivery } from "src/icons/FastDelivery";
import { FreeReturns } from "src/icons/FreeReturns";
import {
  BrandGateLevelInCustomerPlatform,
  ProductVariation,
  PromotionDisplayType,
  SingleProduct,
  WishlistItemType,
} from "src/types/graphql.d";
import { AppCtx } from "src/contexts/app.context";
import { ToggleWishListButton } from "src/molecules/ToggleWishListButton";
import { useToggleWishlistProductItem } from "src/hooks/toggleWishlistItem";
import { useAddToWishList } from "src/hooks/useAddToWishlist";
import { useDeleteWishlistItem } from "src/hooks/deleteWishlistItem";
import { Overlay } from "src/molecules/Overlay";
import { MobileSizeOverlay } from "src/molecules/MobileSizeOverlay";
import { useSegment } from "src/hooks/useSegment";
import currencyMap from "src/utils/currencyMap";
import { useTheme, Theme } from "@emotion/react";
import {
  WishListButtonType,
  WishListIconType,
} from "src/molecules/ToggleWishListButton/ToggleWishListButton";
import { SEGMENT_TOGGLE_WISHLIST_TYPE } from "src/segment";
import { ProductOrderCartButton } from "src/molecules/ProductOrderCartButton";
import { UpdateCartData } from "src/hooks/useUpdateCart";
import {
  MobileAddToCartFixed,
  Ruler,
  SelectSizeError,
  SizeGuideButton,
  SizeGuideLabel,
  SizeOption,
  StyledAddingToCartButton,
  StyledProductOrder,
} from "./ProductOrder.styled";
import {
  getChooseSizeText,
  getSumStockQuantity,
  sanitizeString,
} from "./utils";
import { formatStandardPrice } from "src/utils/ct/priceHelper";
import { useMiscellaneous } from "src/hooks/useMiscellaneous";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import NoSSR from "src/atoms/NoSSR";
import { usePromotionContext } from "src/hooks/usePromotionContext";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { popPostLoginAddedProduct } from "src/utils/postLoginCartAdded";
import {
  GATED_ADD_PRODUCT_TO_FAVOURITE_SOURCE,
  GATED_ADD_TO_PRODUCT_SOURCE,
} from "src/constants/gatedItem";

interface Props {
  product: SingleProduct;
  hasAddToWishlistButton: boolean;
  addToCartLoading: boolean;
}

interface ProductOrderProps extends Props {
  className?: string;
  hasSizeGuide: boolean;
  onAddToCart: (
    variationId: string,
    callback: (data?: UpdateCartData, error?: unknown) => void
  ) => void;
  handleSizeChange: (size: ProductVariation) => void;
  onOpenSizeGuide?: () => void;
  openLoginRegisterModal?: (actionSource: string, variationId?: string) => void;
  shippingAndDeliveryInfo?: boolean;
  sizeGuideRef?: RefObject<HTMLDivElement>;
  queryID?: string;
  objectID?: string;
  algoliaIndex?: string;
  withMobileOverlay?: boolean;
  hasFlashSaleEnded?: boolean;
  customerType?: "existing" | "new" | "guest";
}

interface CallToActionWidgetProps extends Props {
  addToCartLoading: boolean;
  handleAddToCart: () => void;
  isOutOfStock: boolean;
  loading: boolean;
  addedToWishlist: boolean;
  onAddToWishList: (type: WishlistItemType, id: string) => void;
  onRemoveFromWishList: (type: WishlistItemType, id: string) => void;
  isDisabled: boolean;
}

const CallToActionWidget: FC<CallToActionWidgetProps> = ({
  addToCartLoading,
  isOutOfStock,
  handleAddToCart,
  hasAddToWishlistButton,
  product,
  loading,
  addedToWishlist,
  onAddToWishList,
  onRemoveFromWishList,
  isDisabled,
}) => {
  return (
    <>
      {!addToCartLoading ? (
        <ProductOrderCartButton
          isOutOfStock={isOutOfStock}
          showLeftIcon={!isOutOfStock}
          onClick={handleAddToCart}
          isDisabled={isDisabled}
        />
      ) : (
        <StyledAddingToCartButton variant="primary" disabled>
          <Trans>Adding to the cart</Trans>
        </StyledAddingToCartButton>
      )}
      {hasAddToWishlistButton && !isOutOfStock && (
        <ToggleWishListButton
          dataTestId="add-to-wishlist-button"
          isAddedToWishList={addedToWishlist}
          itemType={WishlistItemType.Product}
          itemId={product.id}
          loading={loading}
          onAddToWishList={onAddToWishList}
          onRemoveWishList={onRemoveFromWishList}
          iconType={WishListIconType.HEART_PAGE}
          buttonType={WishListButtonType.OUTLINED_ICON}
        />
      )}
    </>
  );
};

const getSizeStock = (quantity: number) => {
  if (quantity === 2) {
    return (
      <span>
        <Trans>Only two available</Trans>
      </span>
    );
  } else if (quantity === 3) {
    return (
      <span>
        <Trans>Only three available</Trans>
      </span>
    );
  }
  return (
    <span>
      <Trans>Last available item</Trans>
    </span>
  );
};

const ProductOrder: FC<ProductOrderProps> = ({
  className,
  product,
  onAddToCart,
  addToCartLoading,
  onOpenSizeGuide,
  hasSizeGuide,
  hasAddToWishlistButton,
  handleSizeChange,
  openLoginRegisterModal,
  shippingAndDeliveryInfo = true,
  sizeGuideRef,
  queryID,
  objectID,
  algoliaIndex,
  withMobileOverlay = false,
  customerType,
}) => {
  const theme: Theme = useTheme();
  const { locale, cartInfo } = useContext(AppCtx);
  const { data: miscellaneousData } = useMiscellaneous();
  const isLoggedIn = useIsLoggedIn();
  const { promotionContextState } = usePromotionContext();

  const currency = currencyMap[locale] || "EUR";
  const [defaultValue, setDefaultValue] = useState<string | undefined>(
    undefined
  );
  const [productVariants, setProductVariants] = useState<ProductVariation[]>(
    []
  );
  const [selectSizeError, setSelectSizeError] = useState(false);
  const [selectedVariationId, setSelectedVariationId] = useState<string>();
  const [mobileSizeOverlayOpened, setMobileSizeOverlayOpened] = useState(false);
  const { enableGatedHomepage } = useFeatureFlags();
  const {
    segmentProductAdded,
    segmentProductFavouritedOrUnfavourited,
    segmentProductSizeSelectorClicked,
  } = useSegment();
  const [dropDownOpened, setDropDownOpened] = useState<boolean>(false);

  const { isMobile } = useBreakpoints();

  const { ref: selectSizeRef, inView: selectSizeInView } = useInView({
    skip: !isMobile,
    rootMargin: "-60px 0px",
  });

  const { ref: addToCartRef, inView: addToCartInView } = useInView({
    skip: !isMobile,
    rootMargin: "-60px 0px -16px",
  });

  const selectedVariation = selectedVariationId
    ? find(product.variations, ({ id }) => selectedVariationId === id)
    : undefined;

  const { addedToWishlist } = useToggleWishlistProductItem(product.id);
  const { addToWishlist, loading: addLoading } = useAddToWishList({
    refetchQueries: [
      "productBySlug",
      "userPreferences",
      "userWishlistMetaData",
    ],
  });

  const { deleteWishListItem, loading: deleteLoading } = useDeleteWishlistItem({
    refetchQueries: ["productBySlug", "userWishlistMetaData"],
  });

  const onAddToWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      if (isLoggedIn) {
        await addToWishlist(itemType, itemId);
      } else if (enableGatedHomepage && openLoginRegisterModal) {
        openLoginRegisterModal(GATED_ADD_PRODUCT_TO_FAVOURITE_SOURCE);
      }

      void segmentProductFavouritedOrUnfavourited({
        product,
        currency,
        eventType: isLoggedIn
          ? SEGMENT_TOGGLE_WISHLIST_TYPE.favourited
          : SEGMENT_TOGGLE_WISHLIST_TYPE.favouritedWhenLoggedOut,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToWishlist, currency, isLoggedIn, product]
  );

  const onRemoveFromWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      await deleteWishListItem(itemType, itemId, {});

      void segmentProductFavouritedOrUnfavourited({
        product,
        currency,
        eventType: SEGMENT_TOGGLE_WISHLIST_TYPE.unFavourited,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteWishListItem]
  );

  const handleChangeVariation = useCallback(
    (_event: any, { value }: any) => {
      setSelectSizeError(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSelectedVariationId(value);

      const size = productVariants.find(
        (productVariant) => productVariant.id === value
      );
      handleSizeChange(size as ProductVariation);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productVariants]
  );

  const totalQuantity = getSumStockQuantity(
    product.variations as ProductVariation[]
  );

  const quantity = selectedVariation
    ? selectedVariation.stock_quantity
    : totalQuantity;

  const isOutOfStock = quantity === 0;

  const handleSizeWasNotSelected = useCallback(() => {
    if (isMobile && !selectSizeInView) {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  }, [selectSizeInView]); // eslint-disable-line react-hooks/exhaustive-deps

  const isBrandGated =
    !!enableGatedHomepage &&
    !isLoggedIn &&
    product?.gate?.level !== BrandGateLevelInCustomerPlatform.NonGated;

  const handleAddToCart = useCallback(
    (selectedVariationId: string) => {
      void onAddToCart(selectedVariationId, (data) => {
        const productCount = data?.updateMyCart?.lineItems.reduce(
          (count, item) => {
            return count + ((item.quantity as number) || 0);
          },
          0
        );
        void segmentProductAdded(
          product,
          selectedVariation?.size as string,
          queryID,
          objectID,
          algoliaIndex,
          selectedVariationId,
          {
            product_count: productCount,
            cart_total: formatStandardPrice(data?.updateMyCart?.totalPrice),
          },
          data?.updateMyCart?.lineItems
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      onAddToCart,
      segmentProductAdded,
      product,
      selectedVariation?.size,
      queryID,
      objectID,
      algoliaIndex,
    ]
  );

  const onAddToCartClick = useCallback(() => {
    if (!selectedVariationId || !selectedVariation) {
      handleSizeWasNotSelected();
      return setSelectSizeError(true);
    }

    if (isBrandGated && openLoginRegisterModal) {
      openLoginRegisterModal(GATED_ADD_TO_PRODUCT_SOURCE, selectedVariationId);
      return;
    }

    handleAddToCart(selectedVariationId);
  }, [
    selectedVariationId,
    selectedVariation,
    isBrandGated,
    openLoginRegisterModal,
    handleAddToCart,
    handleSizeWasNotSelected,
  ]);

  const handleOpenMobileSize = useCallback(() => {
    if (!dropDownOpened) {
      setDropDownOpened(true);
      void segmentProductSizeSelectorClicked(product);
    }

    if (isMobile) {
      setMobileSizeOverlayOpened(true);
    }
  }, [isMobile, dropDownOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseMobileSize = useCallback(
    () => setMobileSizeOverlayOpened(false),
    []
  );

  const isAsicsShoes = () => {
    const brandName = product.brand.name.toLowerCase() === "asics";
    const asicsCategories = [14119, 14471, 21282, 21314];
    const isShoes =
      !!product.categories &&
      product.categories.filter(
        (value) => -1 !== asicsCategories.indexOf(value as number)
      ).length > 0;

    return brandName && isShoes && !isOutOfStock;
  };

  useEffect(() => {
    if (
      isLoggedIn &&
      ((customerType === "existing" && cartInfo?.id) || customerType === "new")
    ) {
      const addToCartData = popPostLoginAddedProduct();

      if (addToCartData?.productId === product.id) {
        handleAddToCart(addToCartData.variationId);
      }
    }
  }, [handleAddToCart, isLoggedIn, product.id, customerType, cartInfo]);

  // if there is only one size preselect that size
  // for products that have one size like helmets sunglasses
  // and for products that have multiple sizes but only one single size available
  useEffect(() => {
    const variants = (product.variations as ProductVariation[]).filter(
      (variation) => variation.stock_quantity
    );
    setProductVariants(variants);
    if (variants.length === 1) {
      const firstVariation = variants[0]?.id;
      if (firstVariation) {
        setSelectedVariationId(firstVariation);
      }
      setDefaultValue(firstVariation);
    }
  }, [product, product.id]);

  const productVariantsPrepared = productVariants.map(
    ({ id, size, stock_quantity }) => ({
      key: id,
      text: product?.size_schema ? (
        <Flex alignItems="flex-end">
          <SizeOption>{size || ""}</SizeOption>
          <Text
            ml={1}
            fontSize={0}
            lineHeight={"17.6px"}
            color="tone.anthraciteLight"
            letterSpacing={"0.4%"}
          >
            {product.size_schema}
          </Text>
        </Flex>
      ) : (
        <SizeOption>{size}</SizeOption>
      ),
      value: id,
      description: stock_quantity <= 3 ? getSizeStock(stock_quantity) : null,
    })
  );

  const hasFlashSaledEnded =
    (promotionContextState.hasPromotionEnded &&
      promotionContextState.promotionDisplayType ===
        PromotionDisplayType.FlashSale) ||
    false;

  return (
    <StyledProductOrder className={className}>
      {isOutOfStock && (
        <Text
          data-testid="product-quantity-label"
          sx={{
            fontSize: 14,
            lineHeight: "20px",
            color: "tone.anthraciteLight",
          }}
        >
          <Trans>This item is currently out of stock.</Trans>
        </Text>
      )}
      {!isOutOfStock && quantity < 7 && (
        <Text
          data-testid="product-quantity-label"
          mt={2}
          sx={{
            fontSize: 14,
            lineHeight: "20px",
            color: "tone.anthraciteLight",
          }}
        >
          <Trans>Only {quantity} left</Trans>
        </Text>
      )}
      {isAsicsShoes() && (
        <Text
          fontSize={[0]}
          color="tone.anthraciteLight"
          data-testid="hacks-asics-shoes"
        >
          <Trans>
            This items fits smaller. If in doubt, choose one size larger.
          </Trans>
        </Text>
      )}
      {hasSizeGuide && (
        <Flex mt={2}>
          <Ruler />
          {/* TODO Temporarily comented until the bug is fix on mode side. feat(PB-380): Hide the FitAnalytics Widget on PDP */}
          {/* {!product?.size_schema && <div id="fit-finder-container" />} */}
          <SizeGuideButton
            data-testid="product-order-size-guide-btn"
            onClick={onOpenSizeGuide}
          >
            <SizeGuideLabel>
              <Trans>View size guide</Trans>
            </SizeGuideLabel>
          </SizeGuideButton>
        </Flex>
      )}
      {!isOutOfStock && (
        <Flex mt={3} ref={selectSizeRef}>
          <Dropdown
            data-testid="product-order-select-size"
            aria-describedby="size-error-alert"
            aria-invalid={selectSizeError}
            defaultValue={defaultValue}
            placeholder={getChooseSizeText(product?.size_schema)}
            value={selectedVariationId}
            options={productVariantsPrepared}
            onChange={!hasFlashSaledEnded ? handleChangeVariation : undefined}
            open={isMobile ? false : undefined}
            onClick={!hasFlashSaledEnded ? handleOpenMobileSize : undefined}
            disabled={hasFlashSaledEnded}
          />
        </Flex>
      )}
      <div ref={addToCartRef}>
        {selectSizeError && (
          <SelectSizeError data-testid="product-order-select-size-msg" mt={1}>
            <Trans
              render={({ translation }) => (
                <span role="alert" id="size-error-alert">
                  {translation}
                </span>
              )}
            >
              Please select a size
            </Trans>
          </SelectSizeError>
        )}
        <Flex mt={2} flexWrap="wrap">
          <CallToActionWidget
            handleAddToCart={onAddToCartClick}
            isOutOfStock={isOutOfStock}
            loading={addLoading || deleteLoading}
            addedToWishlist={!!addedToWishlist}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onAddToWishList={onAddToWishList}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRemoveFromWishList={onRemoveFromWishList}
            product={product}
            hasAddToWishlistButton={hasAddToWishlistButton}
            addToCartLoading={addToCartLoading}
            isDisabled={hasFlashSaledEnded}
          />
        </Flex>
        {isMobile && !addToCartInView && (
          <NoSSR>
            <MobileAddToCartFixed>
              {selectSizeError && (
                <SelectSizeError
                  data-testid="product-order-select-size-msg"
                  mt={1}
                >
                  <Trans>Please select a size</Trans>
                </SelectSizeError>
              )}
              <Flex mt={2} flexWrap="wrap">
                <CallToActionWidget
                  handleAddToCart={onAddToCartClick}
                  isOutOfStock={isOutOfStock}
                  loading={addLoading || deleteLoading}
                  addedToWishlist={!!addedToWishlist}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onAddToWishList={onAddToWishList}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onRemoveFromWishList={onRemoveFromWishList}
                  product={product}
                  hasAddToWishlistButton={hasAddToWishlistButton}
                  addToCartLoading={addToCartLoading}
                  isDisabled={hasFlashSaledEnded}
                />
              </Flex>
            </MobileAddToCartFixed>
          </NoSSR>
        )}
      </div>
      {shippingAndDeliveryInfo && (
        <>
          <Flex
            mt="space16"
            flexWrap="wrap"
            flexDirection={["column", "column", "column", "row"]}
            justifyContent="space-between"
          >
            {miscellaneousData?.miscellaneous.pdp.shipping && (
              <Flex mb={2} sx={{ whiteSpace: "nowrap" }}>
                <FreeShipping width="27px" height="18px" role="presentation" />
                <Box
                  ml="space8"
                  fontSize="fontSize14"
                  sx={{
                    lineHeight: "18px",
                    letterSpacing: "0.35px",
                    color: theme.colors.tone.black,
                  }}
                >
                  {sanitizeString(
                    miscellaneousData?.miscellaneous.pdp.shipping
                  )}
                </Box>
              </Flex>
            )}
          </Flex>
          {miscellaneousData?.miscellaneous.pdp.delivery && (
            <Flex mb={2} sx={{ whiteSpace: "nowrap" }}>
              <FastDelivery width="27px" height="18px" role="presentation" />
              <Box
                ml="space8"
                fontSize="fontSize14"
                sx={{
                  lineHeight: "18px",
                  letterSpacing: "0.35px",
                  color: theme.colors.tone.black,
                }}
                data-testid="free-delivery-message"
              >
                {sanitizeString(miscellaneousData?.miscellaneous.pdp.delivery)}
              </Box>
            </Flex>
          )}
          {miscellaneousData?.miscellaneous.pdp.returns && (
            <Flex mb={2} sx={{ whiteSpace: "nowrap" }}>
              <FreeReturns width="27px" height="18px" role="presentation" />
              <Box
                ml="space8"
                fontSize="fontSize14"
                sx={{
                  lineHeight: "18px",
                  letterSpacing: "0.35px",
                  color: theme.colors.tone.black,
                }}
              >
                {sanitizeString(miscellaneousData?.miscellaneous.pdp.returns)}
              </Box>
            </Flex>
          )}
        </>
      )}
      {isMobile && withMobileOverlay && (
        <Overlay
          isOpen={mobileSizeOverlayOpened}
          isActive={mobileSizeOverlayOpened}
          onClose={handleCloseMobileSize}
          excludeFromOutside={[sizeGuideRef as RefObject<HTMLDivElement>]}
        >
          <MobileSizeOverlay
            onClose={handleCloseMobileSize}
            openSizeGuide={onOpenSizeGuide}
            hasSizeGuide={hasSizeGuide}
            addToCartLoading={addToCartLoading}
            handleAddToCart={onAddToCartClick}
            options={productVariantsPrepared}
            selected={selectedVariationId}
            handleOption={handleChangeVariation}
          />
        </Overlay>
      )}
      {isMobile && !withMobileOverlay && mobileSizeOverlayOpened && (
        <MobileSizeOverlay
          onClose={handleCloseMobileSize}
          openSizeGuide={onOpenSizeGuide}
          hasSizeGuide={hasSizeGuide}
          addToCartLoading={addToCartLoading}
          handleAddToCart={onAddToCartClick}
          options={productVariantsPrepared}
          selected={selectedVariationId}
          handleOption={handleChangeVariation}
        />
      )}
    </StyledProductOrder>
  );
};

export default ProductOrder;
