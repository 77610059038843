import App, { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import { css, Global, ThemeProvider } from "@emotion/react";
import SwiperCore, {
  Autoplay,
  Keyboard,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";
import { polyfill } from "smoothscroll-polyfill";
import Cookies from "js-cookie";
import { Intl, Locale, LokaliseLocales, StoreKey } from "lib/Intl";
import { withApollo } from "lib/withApollo";
import { withRedux } from "lib/withRedux";
import { Page } from "src/atoms/Page";
import { theme } from "src/theme";
import GlobalStyles from "src/GlobalStyles";
import { AppCtx, AppState, ViewedProductState } from "src/contexts/app.context";
import { LineItem, Money } from "src/types/ctgraphql.d";
import { AddedToCartProduct } from "src/types/addedToCardProduct.d";
import { isClient } from "src/utils/isClient";
import { getStoreConfig } from "src/utils/storeConfig";
import { lineItemsTotalPrice } from "src/utils/ct/totalPrice";
import { getCartItemAvailability } from "src/utils/ct/cartItemAvailabilityHelper";
import { FeatureFlags } from "src/types/featureFlags";
import { TranslationProvider } from "src/providers/TranslationProvider";
import { useUserWishlistMeta } from "src/hooks/useUserWishlistMeta";
import { useSessionError } from "src/hooks/useSessionError";
import { useStoreSettings } from "src/hooks/useStoreSettings";
import { Session } from "next-auth";
import {
  getSession,
  Provider as SessionProvider,
  useSession,
} from "next-auth/client";
import NativeAppLayout from "src/Layouts/NativeAppLayout";
import "rc-slider/assets/index.css";
import { PrivateSaleData } from "src/types/PrivateSaleData";
import { useUserProfile } from "src/hooks/useUserProfile";
import { updateSession } from "src/utils/updateSession";
import { PageViewTracker } from "src/atoms/PageViewTracker";
import {
  ConsentManagerBanner,
  COOKIE_NAME,
} from "src/molecules/ConsentMangerBanner";
import AlgoliaContextProvider from "src/providers/AlgoliaContextProvider";
import { useNavigationItems } from "src/hooks/useNavigationItems";
import { useSelector } from "react-redux";
import { getGenderId } from "src/modules/page";
import NavigationContext, {
  NavigationState,
} from "src/contexts/navigation.context";
import {
  ShopType,
  UserPreferences as UserPreferencesInterface,
  UserPreferencesByEmail,
} from "src/types/graphql.d";
import { EppoRandomizationProvider } from "src/providers/EppoRandomizationProvider";
import { Messages } from "@lingui/core";
import { loadCatalog } from "src/hooks/useLinguiInit";
import { getLokaliseLocale } from "src/utils/getIsoLocale";
import { UserPreferences } from "src/atoms/UserPreferences";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import "./global.css";
import { PageHead } from "src/molecules/PageHead";
import { brandTranslations } from "src/utils/routeTranslations";
import {
  OTRIUM_NO_GATE_ENABLED,
  URL_PARAM_EXTERNAL_SOURCE,
} from "src/constants/gatedItem";

SwiperCore.use([Navigation, Pagination, Keyboard, Autoplay, Thumbs]);

interface ParsedCookie {
  custom?: Record<string, boolean>;
}

if (isClient) {
  /**
   * NOTE: Polyfill to fix Swiper smooth animation in Safari
   *
   * Accordint to Swiper API (https://swiperjs.com/api/) with cssMode={true}:
   * "Changing slides with anything except touch/swipe and mousewheel will happen without
   * transition in browsers without scrollTo.behaviour = 'smooth' support (e.g. in desktop and iOS Safari)"
   */
  polyfill(); // eslint-disable-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
}

const getSmartBannerFeatureFlag = (appLocale: Locale): boolean => {
  const locales = [
    "de",
    "fr",
    "nl",
    "en",
    "de-at",
    "nl-be",
    "fr-be",
    "en-us",
    "en-gb",
    "es-es",
    "it-it",
    "pl-pl",
  ];

  return locales.includes(appLocale);
};

const AppContainer = (props: Props) => {
  const {
    Component,
    intl,
    featureFlags,
    privateSaleData,
    adyenMerchantIdData,
    serverAccessToken,
    collection,
    ...pageProps
  } = props;

  const [session] = useSession();
  const cookie = Cookies.get(COOKIE_NAME);
  const router = useRouter();
  const [userPreferences, setUserPreferences] = useState<
    UserPreferencesInterface | UserPreferencesByEmail | undefined
  >();
  const shopType = useSelector(getGenderId) || ShopType.Women;
  const { data: navigationItemsData, loading: navigationItemsDataLoading } =
    useNavigationItems(shopType);
  const [addedProdToCart, setAddCart] = useState<AddedToCartProduct | null>(
    null
  );
  const [homePageMounted, setHomepageMounted] = useState(false);
  const [viewedProductState, setViewedProductState] =
    useState<ViewedProductState>(ViewedProductState.UNINITIALIZED);
  const [displayConsentManager, setDisplayConsentManager] = useState(!cookie);

  const [initializedConsent, setInitializedConsent] = useState(false);

  const [cartInfo, setCart] = useState({
    id: "",
    version: 0,
  });
  const [
    cartMinLineItemsTotalPriceDiffValue,
    setCartMinLineItemsTotalPriceDiffValue,
  ] = useState<Money | null>(null);

  const locale =
    (isClient ? window?.__NEXT_DATA__?.props?.intl.locale : intl?.locale) ||
    "en";

  const storeKey =
    (isClient
      ? (window?.__NEXT_DATA__?.props?.intl?.storeKey as StoreKey)
      : intl?.storeKey) || "";

  const adyenMerchantId =
    (isClient
      ? window?.__NEXT_DATA__?.props?.adyenMerchantIdData
      : adyenMerchantIdData) || "";

  const wishlists =
    (isClient
      ? window?.__NEXT_DATA__?.props?.featureFlags?.wishlists &&
        serverAccessToken !== null
      : featureFlags?.wishlists && serverAccessToken !== null) || false;

  const referAFriend =
    (isClient
      ? window?.__NEXT_DATA__?.props?.featureFlags?.referAFriend
      : featureFlags?.referAFriend) || false;

  const privateSale =
    (isClient
      ? window?.__NEXT_DATA__?.props?.featureFlags?.privateSale
      : featureFlags?.privateSale) || false;

  const expressCheckout =
    (isClient
      ? window?.__NEXT_DATA__?.props?.featureFlags?.expressCheckout
      : featureFlags?.expressCheckout) || false;

  const employeeSaleData = (isClient
    ? window?.__NEXT_DATA__?.props?.privateSaleData
    : privateSaleData) || { email: "", shopType: [] };

  const collectionEmail =
    (isClient
      ? window?.__NEXT_DATA__?.props?.collection?.email
      : collection?.email) || "";

  const { data: userData } = useUserProfile();
  const { data: storeSettingsData, refetch: refetchStoreSettings } =
    useStoreSettings();

  // appLocale can be ghost domain locale or intl locale
  // zz will be converted here to english but will be kept in graphql
  // accept-language headers
  const appLocale: Locale = locale === "zz" ? "en" : locale;
  const isPrivateSale =
    (privateSale && employeeSaleData.email.length > 0) ||
    session?.user?.isEmployeeSale ||
    false;

  const setCartMinLineItemsTotalPriceDiff = (
    lineItems: LineItem[] | undefined
  ): void => {
    const inStockLineItems = lineItems?.filter((lineItem) => {
      const { isItemOutOfStock } = getCartItemAvailability(lineItem);
      return !isItemOutOfStock;
    });

    if (!inStockLineItems?.length) {
      setCartMinLineItemsTotalPriceDiffValue(null);
      return;
    }

    const cartLineItemsTotalPrice = lineItemsTotalPrice(inStockLineItems);

    if (
      !cartLineItemsTotalPrice.centAmount ||
      !storeSettingsData?.storeSettings?.min_cart_subtotal_price_cent_amount
    ) {
      setCartMinLineItemsTotalPriceDiffValue(null);
    } else {
      const cartMinLineItemsTotalPriceDiff =
        storeSettingsData.storeSettings.min_cart_subtotal_price_cent_amount -
        cartLineItemsTotalPrice.centAmount;

      setCartMinLineItemsTotalPriceDiffValue({
        ...cartLineItemsTotalPrice,
        centAmount:
          cartMinLineItemsTotalPriceDiff > 0
            ? cartMinLineItemsTotalPriceDiff
            : 0,
      });
    }
  };
  const shouldScrollToFooter = router.query.section === "opt-in";

  const appState: AppState = {
    locale: appLocale,
    storeKey,
    storeConfig: getStoreConfig(storeKey),
    userPreferences,
    setUserPreferences: (userPreferencesData) => {
      setUserPreferences(userPreferencesData);
    },
    employeeSaleData,
    adyenMerchantId,
    addedProdToCart,
    featureFlags: {
      wishlists,
      smartBanner: getSmartBannerFeatureFlag(appLocale),
      storeBadges: ["de", "fr", "nl", "en", "de-at", "nl-be", "fr-be"].includes(
        appLocale
      ),
      referAFriend,
      privateSale,
      expressCheckout,
    },
    collection: {
      email: collectionEmail,
    },
    isPrivateSale,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isGhostDomain: locale === "zz",
    setAddedProdToCart: (cartProduct: AddedToCartProduct | null) =>
      setAddCart(cartProduct),
    cartInfo,
    setCartInfo: (id: string, version: number) => {
      setCart({
        id,
        version,
      });
    },
    cartMinLineItemsTotalPriceCentAmount:
      storeSettingsData?.storeSettings?.min_cart_subtotal_price_cent_amount ||
      null,
    cartMinLineItemsTotalPriceDiff: cartMinLineItemsTotalPriceDiffValue,
    setCartMinLineItemsTotalPriceDiff,
    refetchStoreSettings,
    viewedProductState,
    setViewedProductState,
    displayConsentManager,
    setDisplayConsentManager,
    shouldScrollToFooter,
    homePageMounted,
    setHomepageMounted,
    initializedConsent,
    setInitializedConsent,
  };
  const navigationState: NavigationState = {
    navigationItems: navigationItemsData?.navigationItems,
    loading: navigationItemsDataLoading || false,
  };

  useEffect(() => {
    if (cookie) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsedCookie: ParsedCookie = JSON.parse(cookie);

        if (parsedCookie.custom && typeof parsedCookie.custom === "object") {
          const customValues = Object.values(parsedCookie.custom);

          if (customValues.includes(true)) {
            setInitializedConsent(true);
          }
        }
      } catch (error) {
        console.error("Failed to parse cookie", error);
      }
    }
    // Force the session reload that usually happens   only when you change tabs/windows
    // Reference: https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
    const triggerSessionReload = () => {
      if (!document.hidden) {
        void getSession({ triggerEvent: true });
      }
    };

    document.addEventListener(
      "visibilitychange",
      () => triggerSessionReload,
      false
    );
    return () => {
      window.removeEventListener("visibilitychange", triggerSessionReload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.userProfile.token) {
      void updateSession({ token: userData.userProfile.token });
    }
  }, [userData?.userProfile.token]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Apply your redirect logic
      if (url.includes("/products")) {
        const onlyPath = url.split("?")[0];
        const keywords = ["new", "sale", "designer", "designers"];

        if (keywords.some((keyword) => onlyPath.includes(keyword))) {
          const newUrl = url.replace("/products", "");
          void router.replace(newUrl);
        }
      }

      if (url.includes("/product-categorie")) {
        if (url.split("/").length > 3) {
          const newUrl = url.replace("/product-categorie", "");
          void router.replace(newUrl);
        } else {
          const newUrl = url.replace("/product-categorie", "");
          void router.replace(`${newUrl}/categories`);
        }
      }

      if (url.includes("/collections")) {
        const match = url.match(/^\/collections\/([^/]+)\/([^/]+)$/);
        if (match) {
          const genderId = match[1];
          const collectionSlug = match[2];

          // Construct the new URL
          const newUrl = `/${genderId}/collections/${collectionSlug}`;
          void router.replace(newUrl);
        }
      }

      if (url.includes("/icons")) {
        const match = url.match(/^\/icons\/([^\/]+)\/(.*)$/);
        if (match) {
          const genderId = match[1];
          const brandSlug = match[2];

          const newUrl = `/${
            brandTranslations[locale as keyof typeof brandTranslations] ||
            "brands"
          }/${brandSlug}/icons/${genderId}`;
          void router.replace(newUrl);
        }
      }

      if (url.includes("/sales")) {
        const match = url.match(/^\/sales\/([^\/]+)\/([^\/?]+)(\?.*)?/);
        if (match) {
          const genderId = match[1];
          const brandSlug = match[2].split("?")[0];
          const queryParams = match[3] || "";

          const newUrl = `/${
            brandTranslations[locale as keyof typeof brandTranslations] ||
            "brands"
          }/${brandSlug}/${genderId}${queryParams}`;

          void router.replace(newUrl);
        }
      }

      if (url.includes("/brand/")) {
        const newUrl = url.replace("/brand", "/brands");
        void router.replace(newUrl);
      }

      if (url.includes("/a-z")) {
        const match = url.match(
          /^\/([^\/]+)\/(all-brands|alle-merken|alle-marken|marques|marki|marcas|tutte-le-marche|brands|brand)\/a-z/
        );
        if (match) {
          const genderId = match[1];

          const newUrl = `/${genderId}/${
            brandTranslations[locale as keyof typeof brandTranslations] ||
            "brands"
          }`;
          void router.replace(newUrl);
        }
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // Cleanup the event listener
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  useUserWishlistMeta();
  useSessionError();

  const isNativeApp =
    router.query.hasOwnProperty("app") || router.route === "/app-login";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const PageLayout = pageProps?.pageProps?.notFound
    ? Page
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      Component.Layout || Page;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const Layout = isNativeApp ? NativeAppLayout : PageLayout;

  return (
    <AppCtx.Provider value={appState}>
      {session?.user.signInPlatform !== AuthProvider.GUEST_LOGIN && (
        <UserPreferences />
      )}
      <NavigationContext.Provider value={navigationState}>
        <EppoRandomizationProvider locale={appLocale}>
          <AlgoliaContextProvider>
            <ThemeProvider theme={theme}>
              <PageViewTracker locale={locale} />
              <Global styles={css(GlobalStyles)} />
              <Layout>
                <Component {...pageProps} />
              </Layout>
              <ConsentManagerBanner />
            </ThemeProvider>
          </AlgoliaContextProvider>
        </EppoRandomizationProvider>
      </NavigationContext.Provider>
    </AppCtx.Provider>
  );
};

interface Props extends AppProps {
  intl: Intl;
  featureFlags: FeatureFlags;
  privateSaleData: PrivateSaleData;
  adyenMerchantIdData: string;
  catalogs: any;
  i18nCatalog: Messages;
  locale: LokaliseLocales;
  addedProdToCart: AddedToCartProduct;
  serverAccessToken?: string;
  collection: { email: string };
  pageProps: {
    session?: Session;
  };
  metaTags: ReactNode;
}

const OtriumApp = (props: Props) => {
  const { i18nCatalog } = props;

  const isPDP = props.router.pathname === "/product/[productSlug]";
  const isGYes = props.router.query.hasOwnProperty(URL_PARAM_EXTERNAL_SOURCE);

  useEffect(() => {
    if (isGYes) {
      localStorage.setItem(OTRIUM_NO_GATE_ENABLED, "true");
    }
  }, [isGYes]);

  return (
    <>
      <PageHead
        title="Otrium"
        metaTags={
          <>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            {props.metaTags}
          </>
        }
        isPDP={isPDP}
      />
      <SessionProvider session={props?.pageProps?.session}>
        <TranslationProvider messages={i18nCatalog}>
          <AppContainer {...props} />
        </TranslationProvider>
      </SessionProvider>
    </>
  );
};

OtriumApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const locale = getLokaliseLocale(appContext.ctx.req?.intl?.locale || "en");
  const i18nCatalog = await loadCatalog(locale);

  return {
    ...appProps,
    i18nCatalog,
  };
};

// @ts-expect-error Upgrade redux library and check if fixed
export default withApollo(withRedux(OtriumApp));
