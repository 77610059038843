import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  CatalogProduct,
  HomepageSingleCategoryProduct,
  WishlistItemType,
} from "src/types/graphql.d";
import { useAddToWishList } from "src/hooks/useAddToWishlist";
import { useDeleteWishlistItem } from "src/hooks/deleteWishlistItem";
import { ProductCard } from "src/molecules/ProductCard";
import { useSegment } from "src/hooks/useSegment";
import { AppCtx } from "src/contexts/app.context";
import currencyMap from "src/utils/currencyMap";
import { useToggleWishlistProductItem } from "src/hooks/toggleWishlistItem";
import { SegmentProductList } from "src/hooks/useSegmentProductList";
import { SEGMENT_TOGGLE_WISHLIST_TYPE } from "src/segment";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { LoginRegisterModal } from "src/molecules/LoginRegisterModal";
import { GatedPopupTrackingProps } from "src/types/GatedPopup";
import { CarouselProduct } from "src/molecules/ProductCarousel/ProductCarousel";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import {
  GATED_CAROUSEL_FAVOURITE_SOURCE,
  GATED_CAROUSEL_LAST_CARD_SOURCE,
  GATED_CAROUSEL_PRODUCT_CARD_SOURCE,
} from "src/constants/gatedItem";
import { DeprecatedAlgoliaSearchProduct } from "src/types/Algolia";

export type ProductRedirect = CarouselProduct | DeprecatedAlgoliaSearchProduct;
interface ProductCartWishlistProps {
  centerText?: boolean;
  getImageElementWrapper?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  hideIconsLabel?: boolean;
  isPersonalised?: boolean;
  position: number;
  product: CatalogProduct | HomepageSingleCategoryProduct;
  segmentProductOnClick: SegmentProductList["segmentProductOnClick"];
  setVisibleProducts: SegmentProductList["setVisibleProducts"];
  showLabels?: boolean;
  showName?: boolean;
  showSizes?: boolean;
  zoomImageOnHover?: boolean;
  queryID?: string;
  algoliaIndex?: string;
  indexName?: string;
  allowAddToCart?: boolean;
  onAddToCart?: (productSlug: string) => void;
  setRedirectTo?: (redirect: string) => void;
  redirectLink?: string;
  trackingData?: GatedPopupTrackingProps;
  setProductRedirect?: (
    type: string,
    product?: ProductRedirect,
    actionSource?: string,
    position?: number
  ) => void;
  noGateLevel?: boolean;
  plpActionSource?: string;
  plpAddToFavoriteActionSource?: string;
}
const ProductCardWishlist: FC<ProductCartWishlistProps> = ({
  getImageElementWrapper,
  hideIconsLabel,
  isPersonalised,
  position,
  product,
  indexName,
  redirectLink,
  trackingData,
  setProductRedirect,
  noGateLevel,
  plpActionSource,
  plpAddToFavoriteActionSource,
  ...rest
}) => {
  const { locale } = useContext(AppCtx);
  const currency = currencyMap[locale] || "EUR";
  const isLoggedIn = useIsLoggedIn();
  const [open, setOpen] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [actionSource, setActionSource] = useState<string>(
    plpActionSource || GATED_CAROUSEL_PRODUCT_CARD_SOURCE
  );
  const { enableGatedHomepage } = useFeatureFlags();

  const { segmentProductFavouritedOrUnfavourited } = useSegment();
  const { addToWishlist, loading: addLoading } = useAddToWishList({
    refetchQueries: ["userWishlistMetaData"],
  });
  const { addedToWishlist } = useToggleWishlistProductItem(product.id);
  const { deleteWishListItem, loading: deleteLoading } = useDeleteWishlistItem({
    refetchQueries: ["userWishlistMetaData"],
  });

  const isLastTile = product?.slug === "last-tile";
  const { pageName, shopType, carouselType } =
    (trackingData as GatedPopupTrackingProps) || {};

  const onGateProductClick = useCallback(() => {
    if (isLoggedIn) {
      return;
    }

    if (isLastTile && redirectLink) {
      setRedirectTo(redirectLink);
      setActionSource(GATED_CAROUSEL_LAST_CARD_SOURCE);
    } else {
      setRedirectTo(`/product/${product.slug}`);
    }

    setOpen(true);
  }, [isLoggedIn, isLastTile, redirectLink, product.slug]);

  const onAddToWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      if (isLoggedIn) {
        await addToWishlist(itemType, itemId);
      } else if (enableGatedHomepage) {
        setActionSource(
          plpAddToFavoriteActionSource || GATED_CAROUSEL_FAVOURITE_SOURCE
        );
        setOpen(true);
      }

      void segmentProductFavouritedOrUnfavourited({
        product,
        currency,
        eventType: isLoggedIn
          ? SEGMENT_TOGGLE_WISHLIST_TYPE.favourited
          : SEGMENT_TOGGLE_WISHLIST_TYPE.favouritedWhenLoggedOut,
        isPersonalised,
        indexName,
      });
    },
    [
      isLoggedIn,
      enableGatedHomepage,
      segmentProductFavouritedOrUnfavourited,
      product,
      currency,
      isPersonalised,
      indexName,
      addToWishlist,
    ]
  );

  const onDeleteWishlistItem = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      await deleteWishListItem(itemType, itemId, {});
      void segmentProductFavouritedOrUnfavourited({
        product,
        currency,
        eventType: SEGMENT_TOGGLE_WISHLIST_TYPE.unFavourited,
        isPersonalised,
        indexName,
      });
    },
    [
      deleteWishListItem,
      segmentProductFavouritedOrUnfavourited,
      product,
      currency,
      isPersonalised,
      indexName,
    ]
  );

  return (
    <>
      <ProductCard
        data-testid="product-card"
        wishlistProgress={addLoading || deleteLoading}
        isAddedToWishList={addedToWishlist}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAddToWishList={onAddToWishList}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onRemoveWishList={onDeleteWishlistItem}
        key={product.id}
        product={product}
        hideIconsLabel={hideIconsLabel}
        position={position}
        getImageElementWrapper={getImageElementWrapper}
        onGateProductClick={onGateProductClick}
        isLastTile={isLastTile}
        noGateLevel={noGateLevel}
        shopType={shopType}
        {...rest}
      />
      {open && (
        <LoginRegisterModal
          open={open}
          redirectTo={redirectTo}
          onClose={() => {
            setOpen(false);
          }}
          trackingData={{
            pageName,
            shopType,
            carouselType,
            actionSource,
            ...(!isLastTile && {
              productName: product.name,
              productId: product.id,
              productImageUrl: product.thumbnail || "",
            }),
            level: product.gate?.level || undefined,
            redirectTo,
          }}
          onRedirect={(type) =>
            setProductRedirect
              ? setProductRedirect(
                  type,
                  product as CarouselProduct,
                  actionSource,
                  position
                )
              : null
          }
        />
      )}
    </>
  );
};

export default ProductCardWishlist;
