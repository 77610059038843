import {
  BrandSaleType,
  ProductCurrency,
  ProductLabel,
  ShopType,
} from "./graphql.d";
import { Hit } from "@algolia/client-search";
import { Hit as DeprecatedHit } from "react-instantsearch-core";
import { BaseHit } from "instantsearch.js";
import { SearchResults } from "algoliasearch-helper";
import {
  BindEventForHits,
  SendEventForHits,
} from "instantsearch.js/es/lib/utils";

interface AlgoliaSearch {
  objectID: string;
  entityID: string;
  _highlightResult?: {
    query?: {
      value: string;
    };
    name?: {
      value: string;
    };
  };
  _rankingInfo?: {
    userScore: number;
    geoPrecision: number;
    geoDistance: number;
  };
  type?: string;
}

export interface DeprecatedAlgoliaSearchProduct extends AlgoliaSearch {
  name: string;
  slug: string;
  price: number;
  regular_price: number;
  brand_name: string;
  image: string;
  genders: string[];
  currency: ProductCurrency;
  labels: ProductLabel[];
  type: string;
  value: string;
  color: string;
  priority: number;
  is_deleted: boolean;
  categories: AlgoliaSearchProductCategory[];
  brand: AlgoliaSearchProductBrand;
}

/**
 * @deprecated use ShopType instead
 */
export type Gender = "women" | "men" | "kids";

// TODO: remove it here SHOP-2665
export interface DeprecatedAlgoliaSearchCategory extends AlgoliaSearch {
  name: string;
  slug: string;
  genders: string[];
  parent: string;
  excluded_from_filters: boolean;
  parent_path_string: string;
  is_deleted: boolean;
  image: string;
}

type DeprecatedSuggestionType =
  | "information"
  | "category"
  | "brand"
  | "collection";

export interface DeprecatedAlgoliaSearchSuggestion extends AlgoliaSearch {
  genders: string[];
  query: string;
  type: DeprecatedSuggestionType;
  slug: string | null;
  url: string | null;
  hasDuplicate: boolean;
  parent_path: string[];
}

export interface AlgoliaFacet {
  name: string;
  data: { [key: string]: string }[];
}

export type HitType = DeprecatedHit<{
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}>;

export interface MultiselectHitType extends HitType {
  items: MultiselectHitType[];
  selected: boolean;
  name?: string;
  parentCategoryId?: string;
  categoryId?: string;
  slug: string;
}

export interface ConfigureProps {
  maxValuesPerFacet: number;
  hitsPerPage: number;
  facets: string[];
  ruleContexts: string[];
  analyticsTags: string[];
  filters: string;
  clickAnalytics: boolean;
  userToken?: string;
}

export type CustomHitsRenderState<THit extends BaseHit> = {
  hits: Array<DeprecatedHit<THit>>;
  results?: SearchResults<DeprecatedHit<THit>>;
  sendEvent: SendEventForHits;
  bindEvent: BindEventForHits;
};

// TODO: deprecate everything above
// https://otrium.atlassian.net/browse/SHOP-2559

export interface AlgoliaIndex {
  indexName: string;
  sortBy?: SortBy;
  defaultSort?: string;
}

export enum SortBy {
  POPULAR = "popular",
  NEW = "_publish_date_desc",
  NEW_DISCOUNT = "_discount_date_desc",
  PRICE = "_price_asc",
  PRICE_DESC = "_price_desc",
}

export type Device = "desktop" | "mobile";

type HighlightAttribute =
  | "query"
  | "brand"
  | "categories"
  | "color"
  | "container_sizes"
  | "genders"
  | "name";

interface HighlightValue {
  value: string;
  matchLevel: "none" | "partial" | "some";
  fullyHighlighted: boolean;
  matchedWords: string[];
}

type HighlightResult = Record<HighlightAttribute, HighlightValue>;

interface BaseMeta {
  id: string;
  meta: string;
  _highlightResult: HighlightResult;
}

export interface ContainerSize extends BaseMeta {
  name: string;
}

export interface ColorFilter extends BaseMeta {
  hex: string;
  icon: string;
  name: string;
}

interface PromotionFilter extends BaseMeta {
  name: string;
}

type PatternFilter = BaseMeta;
type StyleFilter = BaseMeta;
type DiscountFilter = BaseMeta;

interface OverlayLogoImage {
  size: string;
  url: string;
}

interface BrandImage {
  url: string;
  device: Device;
}

interface AlgoliaSearchProductCategory extends BaseMeta {
  ancestorCount: number;
  meta_v2: string;
  name: string;
  slug: string;
}

interface AlgoliaSearchProductBrand extends BaseMeta {
  name: string;
  slug: string;
  designer_brand: boolean;
  designer_only: boolean;
  good_on_you: string[];
  is_private_sale: boolean;
  departments: string[];
  images?: BrandImage[];
}

interface AlgoliaSearchProduct {
  name: string;
  slug: string;
  genders: ShopType[];
  is_deleted: boolean;
  price: number;
  regular_price: number;
  brand_name: string;
  image: string;
  currency: ProductCurrency;
  stock_amount: number;
  otrium_score: number;
  container_sizes: ContainerSize[];
  number_of_sizes: number;
  color: ColorFilter;
  pattern: PatternFilter;
  style: StyleFilter;
  categories: AlgoliaSearchProductCategory[];
  doc_version: number;
  is_clearance_product: boolean;
  promotion_filter: PromotionFilter[];
  discount_date: number;
  publish_date: number;
  available_sizes_label: string;
  new_in_score: number;
  brand: AlgoliaSearchProductBrand;
  labels?: ProductLabel[];
  drop_number: number;
  season: string;
  ban_code: string;
  publish_date_human: string;
  discount_buckets: DiscountFilter[];
  number_of_sizes_modified: number;
  search_keywords: string[];
  collections: string[];
}

export type GoodOnYouPillarNames = "animals" | "planet" | "people" | "overall";
export type GoodOnYouScores = Record<GoodOnYouPillarNames, number>;
export type GoodOnYouPillarsType = Record<GoodOnYouPillarNames, boolean>;
export interface GoodOnYouCertification {
  id: number;
  name: string;
}
export interface GoodOnYouInfo {
  score_meta: string;
  certifications: GoodOnYouCertification[];
  scores: GoodOnYouScores;
  pillars: GoodOnYouPillarsType;
}

export interface AlgoliaSearchBrand {
  // deprecated
  objectID: string;
  name: string;
  slug: string;
  genders: ShopType[];
  is_deleted: boolean;
  designer_brand: boolean;
  good_on_you_info?: GoodOnYouInfo;
  is_gold: boolean;
  logo: string;
  labels: Record<ShopType, ProductLabel[]>;
  overlay_logo_images: OverlayLogoImage[];
  images: Record<ShopType, BrandImage[]>;
  cover_images: Record<ShopType, string | null>;
  description: string;
  discount_labels: Record<ShopType, string | null>;
  about_brand_text: string;
  about_popup_image: string;
  coming_soon?: boolean;
  store_publish_date: number;
  brand_sale_type: BrandSaleType;
}

export interface AlgoliaSearchCategory {
  name: string;
  slug: string;
  genders: ShopType[];
  is_deleted: boolean;
  parent: string;
  excluded_from_filters: boolean;
  parent_path_string: string;
  image: string;
  doc_version: number;
}

export type SuggestionType =
  | "information"
  | "category"
  | "brand"
  | "collection";

export interface AlgoliaSearchSuggestion {
  genders: ShopType[];
  entityID: string;
  is_deleted: boolean;
  doc_version: number;
  parent_path: string[];
  query: string;
  slug: string | null;
  type: SuggestionType;
  url: string | null;
  _highlightResult: HighlightResult;
  hasDuplicate?: boolean;
}

type AlgoliaSearchEntity =
  | AlgoliaSearchBrand
  | AlgoliaSearchCategory
  | AlgoliaSearchProduct
  | AlgoliaSearchSuggestion;

export interface SearchFacets {
  facets?: Record<string, Record<string, number>>;
  facetStats?: Record<
    string,
    {
      /**
       * The minimum value in the result set.
       */
      min: number;
      /**
       * The maximum value in the result set.
       */
      max: number;
      /**
       * The average facet value in the result set.
       */
      avg: number;
      /**
       * The sum of all values in the result set.
       */
      sum: number;
    }
  >;
}

export interface SearchMeta {
  nbHits: number;
  page: number;
  nbPages: number;
  queryID?: string;
}

export interface SearchResults<T> {
  results: Hit<T>[];
  meta: SearchMeta;
  facets: SearchFacets;
}

export enum ContextKeySearch {
  recommendedProducts = "search_recommended_products_carousel",
  searchSuggestions = "search_suggestions",
  searchProducts = "search_products",
}
