import { FC, useState } from "react";
import {
  CatalogProduct,
  HomepageSingleCategoryProduct,
  WishlistProduct,
} from "src/types/graphql.d";
import { CarouselHeader } from "src/molecules/CarouselHeader";
import { Carousel } from "src/molecules/Carousel";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import { SEGMENT_LIST_FORMAT, SEGMENT_LIST_TYPE } from "src/segment";
import { useImageHeight } from "src/hooks/useImageHeight";
import { CarouselOverlay } from "src/molecules/CarouselOverlay";
import { CarouselMarginSize } from "src/molecules/Carousel/Carousel.styled";
import { ProductCarouselInfoText } from "./ProductCarouselInfoText";
import {
  StyledCarouselContainer,
  StyledProductCarousel,
} from "./ProductCarousel.styled";
import { ConditionalWrapper } from "src/atoms/ConditionalWrapper";
import FavouritesSizeSelector from "src/molecules/FavouritesSizeSelector/FavouritesSizeSelector";
import { LoginRegisterModal } from "src/molecules/LoginRegisterModal";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { GatedPopupTrackingProps } from "src/types/GatedPopup";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { ProductRedirect } from "src/organisms/ProductCardWishlist/ProductCardWishlist";

export type CarouselProduct =
  | CatalogProduct
  | HomepageSingleCategoryProduct
  | WishlistProduct;

interface ProductCarouselProps {
  title?: string;
  subTitle?: string;
  headerLinkHref?: string;
  headerLinkAs?: string;
  products?: CarouselProduct[] | null;
  slidesDesktop?: number;
  slidesTablet?: number;
  slidesMobile?: number;
  zoomImageOnHover?: boolean;
  shouldReset?: boolean;
  showInfoText?: boolean;
  marginSize?: CarouselMarginSize;
  marginSizeMobile?: CarouselMarginSize;
  titleForTracking?: string;
  overlayBgColor?: string;
  isPersonalised?: boolean;
  genderId: string;
  allowAddToCart?: boolean;
  responseSourceType?: string | null;
  trackingData?: GatedPopupTrackingProps;
  isPDPCarousel?: boolean;
}

const ProductCarousel: FC<ProductCarouselProps> = ({
  title,
  subTitle,
  headerLinkHref,
  headerLinkAs,
  products,
  slidesDesktop,
  slidesTablet,
  slidesMobile,
  zoomImageOnHover = false,
  shouldReset,
  showInfoText = false,
  marginSize = "l",
  marginSizeMobile,
  titleForTracking,
  overlayBgColor,
  isPersonalised = false,
  genderId,
  allowAddToCart = false,
  responseSourceType,
  trackingData,
  isPDPCarousel,
}) => {
  const [imageElementWrapperHeight, getImageElementWrapper] = useImageHeight();
  const [open, setOpen] = useState<boolean>(false);
  const isLoggedIn = useIsLoggedIn();
  const { enableGatedHomepage } = useFeatureFlags();

  const listFormat = SEGMENT_LIST_FORMAT.carousel;
  const listId = title || titleForTracking || "";
  const listType = SEGMENT_LIST_TYPE.category;
  const { i18n } = useLingui();
  const { pageName, shopType, carouselType } =
    (trackingData as GatedPopupTrackingProps) || {};

  const { segmentProductOnClick, segmentViewAllOnClick, setVisibleProducts } =
    useSegmentProductList({
      listFormat,
      listId,
      listType,
      numberOfItems: products?.length || 0,
      isPersonalised,
      shopType: genderId,
      responseSourceType,
    });

  const setRedirectData = (
    type: string,
    product?: ProductRedirect,
    actionSource?: string,
    position?: number
  ) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      numberOfItems: products?.length || 0,
      isPersonalised,
      position,
      product,
      productListMeta: {
        listFormat,
        listId,
        listType,
        shopType: genderId,
      },
      actionSource: actionSource || "view_all",
    });
  };

  const [productSlug, setProductSlug] = useState<string | undefined>();

  const handleViewAllClick = () => {
    if (!isLoggedIn && enableGatedHomepage) {
      setOpen(true);
    } else {
      segmentViewAllOnClick();
    }
  };

  const allProducts = products?.length
    ? !isLoggedIn && enableGatedHomepage && !isPDPCarousel
      ? [
          ...products,
          {
            slug: "last-tile",
            id: "last-tile",
            name: t(i18n)`Log in`,
            brand: {
              name: t(i18n)`Log in to unlock even more fashion brands`,
            },
            thumbnail: "/static/images/carousel-last-card.gif",
          },
        ]
      : products
    : [];

  const showGatedBehaviour = enableGatedHomepage ? isLoggedIn : true;

  return (
    <StyledProductCarousel showInfoText={showInfoText}>
      {showInfoText && (
        <ProductCarouselInfoText
          title={title}
          subTitle={subTitle}
          linkHref={showGatedBehaviour ? headerLinkHref : undefined}
          viewAllOnClick={handleViewAllClick}
        />
      )}
      {!showInfoText && (title || subTitle) && (
        <CarouselHeader
          title={title}
          subTitle={subTitle}
          href={showGatedBehaviour ? headerLinkHref : undefined}
          as={headerLinkAs}
          isGated={!!enableGatedHomepage && !isLoggedIn}
          viewAllOnClick={isPDPCarousel ? undefined : handleViewAllClick}
        />
      )}
      <StyledCarouselContainer showInfoText={showInfoText}>
        <Carousel
          slidesDesktop={slidesDesktop}
          slidesTablet={slidesTablet}
          slidesMobile={slidesMobile}
          shouldReset={shouldReset}
          marginSize={marginSize}
          marginSizeMobile={marginSizeMobile}
          imageElementWrapperHeight={imageElementWrapperHeight}
          items={(allProducts ?? []).map((product, index) => {
            const lastElementIndex = 1;
            return (
              <ConditionalWrapper
                key={product.slug}
                wrapper={(children) => (
                  <CarouselOverlay
                    key={product.id}
                    link={showGatedBehaviour ? headerLinkHref : undefined}
                    overlayBgColor={overlayBgColor}
                    onClick={handleViewAllClick}
                  >
                    {children}
                  </CarouselOverlay>
                )}
                needsWrapper={
                  !!(
                    headerLinkHref &&
                    showGatedBehaviour &&
                    index === (allProducts ?? []).length - lastElementIndex
                  )
                }
              >
                <ProductCardWishlist
                  key={product.slug}
                  position={index}
                  product={
                    product as CatalogProduct | HomepageSingleCategoryProduct
                  }
                  segmentProductOnClick={segmentProductOnClick}
                  setVisibleProducts={setVisibleProducts}
                  centerText
                  showLabels={false}
                  showName={false}
                  showSizes={false}
                  zoomImageOnHover={zoomImageOnHover}
                  getImageElementWrapper={getImageElementWrapper}
                  isPersonalised={isPersonalised}
                  allowAddToCart={allowAddToCart}
                  onAddToCart={setProductSlug}
                  redirectLink={headerLinkHref}
                  trackingData={trackingData}
                  setProductRedirect={setRedirectData}
                />
              </ConditionalWrapper>
            );
          })}
        />
      </StyledCarouselContainer>
      {allowAddToCart && (
        <FavouritesSizeSelector
          productSlug={productSlug}
          onClose={() => setProductSlug(undefined)}
        />
      )}
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          redirectTo={headerLinkHref}
          trackingData={{
            pageName,
            shopType,
            carouselType,
            actionSource: "view_all",
            redirectTo: headerLinkHref,
          }}
          onRedirect={setRedirectData}
        />
      )}
    </StyledProductCarousel>
  );
};

export default ProductCarousel;
